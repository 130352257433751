import React, { useState, useEffect } from 'react';
import Modal from '../Modal-wrapper/modal';
import { Card, CardBody, CardHeader, CardFooter } from '@paljs/ui/Card';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import { Button } from '@paljs/ui/Button';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { getAllTemplates, getTemplateByName } from '../../actions/templateAction';
import Select from '@paljs/ui/Select';
import styled from 'styled-components';
import { InputGroup } from '@paljs/ui/Input';
import _ from "lodash";
import { scheduleMessages } from '../../actions/whatsAppActions';


const SelectStyled = styled(Select)`
  width: 15rem;
`;

const scheduleMessageForMultipleUsers = ({ selectedRows, onClose, onSubmit }) => {
    const [isOpen, setOpen] = useState(false)
    const [value, setValue] = useState();
    const [templateNameList, setTemplateNameList] = useState([])
    const [templateName, setTemplateName] = useState('')
    const [category, setCategory] = useState('Not selcted yet')
    const [message, setMessage] = useState('')
    useEffect(() => {
        getTemplates();
    }, [isOpen]);

    const onTemplateSelect = async (value) => {
        const result = await getTemplateByName(value.value);
        setCategory(result?.data?.category);
        setMessage(result?.data?.message)
        setTemplateName(value.value)
    }

    const getTemplates = async () => {
        const response = await getAllTemplates();
        const nameList = []
        response.data.map((item) => {
            nameList.push({ value: item.template_name, label: item.template_name })
        });
        setTemplateNameList(nameList);
    };

    const onScheduleSubmit = async () => {
        const schedule_date = moment(value['$d']).utc().format('YYYY-MM-DD HH:mm:ss')

        const data = selectedRows.map((item) => {
            const compiled = _.template(message);
            return {
                category,
                message_name: templateName,
                medium: "WHATSAPP",
                schedule_date,
                sent_to: item.uid,
                mobile: item.mobile,
                message: compiled({
                    user: {
                        'name': item.name,
                        'mobile': item.mobile,
                        'gautra': item.gautra,
                        'node_id': item.uid,
                        'village': item.village,
                    },
                    added_by: {
                        'gautra': item.adder_gautra,
                        'mobile': item.adder_mobile,
                        'name': item.adder_name,
                        'node_id': item.adder_id,
                        'village': item.adder_village
                    }
                })
            }
        })
        console.log("data to be submitted", data);
        const result = await scheduleMessages({msg_obj_list: data})
        console.log("after submitting data", result);
        onSubmit(result);
        onClose();

    }

    return (
        <>

            <Modal onClose={onClose}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                <SelectStyled status={!templateName ? 'Danger' : 'Info'} style={{ padding: '5px' }} options={templateNameList} placeholder="Select Name" onChange={(value) => { onTemplateSelect(value) }} />
                                <span style={{ padding: '10px' }}>Category : {category}</span>
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: 'center' }}>

                                <InputGroup fullWidth style={{ margin: '18px', display: 'block' }}>
                                    <div>Message : (Read Only) </div>
                                    <textarea
                                        className='textP'
                                        placeholder='type Message'
                                        style={{ width: '450px', minHeight: '5rem' }}
                                        value={message}
                                        readOnly
                                    />
                                </InputGroup>
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        onChange={(newValue) => setValue(newValue)}
                                    />
                                </LocalizationProvider>
                            </Col>
                            <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button status="Info" type="button" shape="SemiRound"
                                    style={{ margin: '10px', display: 'block' }}
                                    onClick={onScheduleSubmit}
                                    disabled={value && templateName ? false : true}
                                >
                                    Schedule Message
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>

                </Card>
            </Modal>
        </>
    )
}

export default scheduleMessageForMultipleUsers;
