import { Card, CardBody, CardHeader, CardFooter } from '@paljs/ui/Card';
import React, { useState, useEffect, useRef } from 'react';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import VillageListOptions from './village-list-options';
import Slider from '@mui/material/Slider';
import { Button } from '@paljs/ui/Button';
import DateRangePicker from "../../components/date/date-range"
import moment from 'moment';

const FilterUser = ({ filterMethod, max_count }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [asyncData, setAsyncData] = useState({});
    const [close, setClose] = useState(false);
    const villageListRef = useRef();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [nodeCount, setNodeCount] = useState<number[]>([0, max_count]);
    const dateRangeRef = useRef();
    const nodeCountSliderChange = (event: Event, newValue: number | number[]) => {
        setNodeCount(newValue)
    }
    const asyncChanges = (data) => {
        setAsyncData(data);
    }
    const onSubmit = () => {
        filterMethod({ ...asyncData, nodeCount, startDate, endDate })
    }
    const onReset = () => {
        setAsyncData({});
        setClose(!close);
        setNodeCount([0, max_count]);
        setStartDate('');
        setEndDate('');
        filterMethod({
            district: '',
            tehsil: '',
            panchayat: '',
            village: '',
            nodeCount: [0, max_count],
            startDate: '',
            endDate: ''
        })
        villageListRef.current.clearData();
        dateRangeRef.current.onReset();
    }
    const onDateRangeToggle = () => {
        setIsOpen(!isOpen);
    };
    const dateRangeChange = (range) => {
        const changedStartDate = moment(range.startDate).format("YYYY-MM-DD");
        const changedEndDate = moment(range.endDate).format("YYYY-MM-DD");
        range.startDate !== range.endDate ? setIsOpen(!isOpen) : '';
        setStartDate(changedStartDate);
        setEndDate(changedEndDate);
    }
    return (
        <>
            <Card status="Info">
                <CardHeader>filter</CardHeader>
                <CardBody>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col>
                            <Button
                                appearance="outline"
                                shape={'Round'}
                                size={'Small'}
                                Status={'Info'}
                                onClick={onDateRangeToggle}
                            >
                                choose date range
                            </Button>

                            <DateRangePicker
                                onChange={dateRangeChange}
                                isOpen={isOpen}
                                ref={dateRangeRef}
                            />
                        </Col>
                        <VillageListOptions onChange={asyncChanges} ref={villageListRef} type='creatable' />
                        <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                            <Col style={{ width: '470px' }}>
                                <p >Node&nbsp;Counts&nbsp;: ({nodeCount[0]} - {nodeCount[1]})</p>
                                <Slider
                                    getAriaLabel={() => 'Node Count range'}
                                    value={nodeCount}
                                    onChange={nodeCountSliderChange}
                                    valueLabelDisplay="auto"
                                    min={0}
                                    max={max_count}
                                />
                            </Col>
                        </Col>
                    </Row>
                </CardBody>
                <CardBody>
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button status="Success" type="button" shape="SemiRound"
                            style={{ margin: '10px', display: 'block' }}
                            onClick={onSubmit}
                        >
                            submit
                        </Button>
                        <Button status="Danger" type="button" shape="SemiRound"
                            style={{ margin: '10px', display: 'block' }}
                            onClick={() => onReset()}
                        >
                            Reset
                        </Button>
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}
export default FilterUser;