import { getApiWrapper, postApiWrapper } from "./token-wrapper-function";
export const getActiveUserInfo = async () => {
  const result = await getApiWrapper(`/api/mongousers`);
  return result;
};


export const ActivityOfUser = async (node) => {
  const result = await getApiWrapper(`/api/activity/${node}`);
  return result;
};

// export const getActiveUserInfoByDate = async (startDate,endDate,callback) => {
//   const Token = window.localStorage.getItem("Token")
//     ? window.localStorage.getItem("Token")
//     : {}
//   if (Token) {
//     await fetch(`/api/mongousers?start_date=${startDate}&end_date=${endDate}`, {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         "Authorization": "Token " + Token
//       }})
//       .then(response => response.json())
//       .then((json) => {
//         // if(json.status){
//         callback(json)
//         // }
//       })
//       .catch(err => console.log('Request Failed', err));
//   }
// };

export const getActiveUserInfoByDate = async (data) => {
  const url = `/api/mongousers?start_date=${data.startDate}&end_date=${data.endDate}`;
  const result = await getApiWrapper(url);
  return result;
}

export const ActivityForUser = async (node) => {
  const url = `/api/node_actions/${node}`;
  const result = await getApiWrapper(url);
  return result;
};

