import React, { useState, useEffect, useRef } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { getActiveUserInfo, getActiveUserInfoByDate } from '../../actions/activityAction';
import './styles.css';
import { navigate, Link } from 'gatsby';
import { format } from 'date-fns';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import LoadingSpinner from '../loader';
import DataTable from 'react-data-table-component';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import Export from '../../components/export_csv/export';
import Modal from '../../components/whatsApp-messages/schedule-message-for-multiple-users';
import { Toastr, ToastrRef, ToastrProps } from '@paljs/ui/Toastr';
import FilterUser from '../../components/filter/activeUserFilter';

const Users = () => {
  const [rows, setRows] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [originalRows, setOriginalRows] = useState([]);
  const [maxCount, setMaxCount] = useState(0);
  const [isLoading, showLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [ranges, setRanges] = useState([
    {
      startDate: null,
      endDate: null,
    },
  ]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isTableLoading, setTableLoader] = useState(false);
  const [toastData, setToastData] = useState<ToastrProps>({
    position: 'topEnd',
    status: 'Success',
    duration: 2000,
    hasIcon: true,
    destroyByClick: true,
    preventDuplicates: false,
  });
  const toastrRef = useRef<ToastrRef>(null);
  useEffect(() => {
    showLoader(true);

    try {
      const response = getActiveUserInfo();
      let max = 0;
      const data = response.data.map((item) => {
        max = item.count > max ? item.count : max;
        return {
          name: item['person_details']?.name,
          gautra: item['person_details']?.gautra,
          village: item?.village_name,
          registered_mobile: item['person_details']?.registered_mobile,
          count: item.count,
          _id: item._id,
          tehsil: item?.tehsil,
          district: item?.district,
          gram_panchayat: item?.gram_panchayat,
        };
      });
      console.log('getting the Active users ', response);
      setMaxCount(max);
      setRows(data);
      showLoader(false);
      setOriginalRows(data);
    } catch (error) {
      // setError('Something went wrong!');
      showLoader(false);
    }
  }, []);

  // const handleDateRangePicker = (ranges: { range1: any }) => {

  //   console.log('ranges', ranges);
  //   const { range1 } = ranges;
  //   setRanges([{ startDate: range1.startDate, endDate: range1.endDate }]);
  //   dateRangeFilterData(range1.startDate, range1.endDate);
  // };

  const tableColumns = [
    {
      name: 'S.No.',
      sortable: false,
      grow: 0.2,
      cell: (row, index, column, id) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      name: 'Name',
      sortable: true,
      selector: (row) => row.name,
      cell: (row) => (
        <a target="_blank" href={`/family-tree?idOfUser=${row._id}`}>
          {row.name}
        </a>
      ),
    },
    {
      name: 'Gautra',
      selector: (row) => row.gautra,
      sortable: true,
    },
    {
      name: 'Village',
      selector: (row) => row.village,
      sortable: true,
    },
    {
      name: 'Registered Mobile No.',
      selector: (row) => row.registered_mobile,
      sortable: true,
    },
    {
      name: 'Count',
      selector: (row) => row.count,
      sortable: true,
    },
    {
      name: 'Actions',
      wrap: true,
      grow: 2,
      left: true,
      cell: (row) => (
        <>
          <Link to={`/Activity/activityByUser?id=${row._id}`}>
            <Button shape={'Round'} size={'Tiny'} Status={'Success'}>
              BY USER
            </Button>
          </Link>
          <Link to={`/Activity/activityForUser?id=${row._id}`}>
            <Button shape={'Round'} size={'Tiny'} Status={'Success'}>
              FOR USER
            </Button>
          </Link>
          <a href={`/whatsapp/templates?node_id=${row._id}`} target="_blank">
            <Button shape={'Round'} size={'Tiny'} Status={'Success'}>
              Whatsapp Message
            </Button>
          </a>
        </>
      ),
    },
  ];
  const handleInputChange = (event) => {
    do_search(event.target.value);
  };

  const do_search = (v) => {
    const value = v.trim();
    const temp_rows = originalRows.filter((e) => JSON.stringify(e).indexOf(value) >= 0);
    setRows(temp_rows);
  };

  const filterMethod = async (attributes) => {
    setTableLoader(true);
    const result = attributes.startDate ? await getActiveUserInfoByDate(attributes) : '';
    let response;
    if (result) {
      response = result.data.map((item) => {
        return {
          name: item['person_details']?.name,
          gautra: item['person_details']?.gautra,
          village: item?.village_name,
          registered_mobile: item['person_details']?.registered_mobile,
          count: item.count,
          _id: item._id,
          tehsil: item?.tehsil,
          district: item?.district,
          gram_panchayat: item?.gram_panchayat,
        };
      });
    } else {
      response = originalRows;
    }
    setStartDate(attributes.startDate);
    setEndDate(attributes.endDate);
    const filteredByDistrict = response.filter((item) => {
      if (attributes?.district) {
        return item.district == attributes.district;
      } else {
        return item;
      }
    });
    console.log('filtere data of district', filteredByDistrict);
    const filteredByTehsil = filteredByDistrict.filter((item) => {
      if (attributes?.tehsil) {
        return item.tehsil == attributes.tehsil;
      } else {
        return item;
      }
    });
    console.log('filtere data of tehsil', filteredByTehsil);
    const filterByPanchayat = filteredByTehsil.filter((item) => {
      if (attributes?.panchayat) {
        return item.gram_panchayat == attributes.panchayat;
      } else {
        return item;
      }
    });
    console.log('filtere data of panchayat', filterByPanchayat);
    const filterByVillage = filterByPanchayat.filter((item) => {
      if (attributes?.village) {
        return item.village == attributes.village;
      } else {
        return item;
      }
    });
    console.log('filtere data', filterByVillage);
    const filterByNodeCount = filterByVillage.filter((item) => {
      return attributes.nodeCount[0] <= item.count && attributes.nodeCount[1] >= item.count;
    });
    setRows(filterByNodeCount);
    setTableLoader(false);
  };
  const actionsMemo = React.useMemo(() => <Export rows={rows} />, [rows]);
  const contextActions = React.useMemo(() => {
    return (
      <Button
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Schedule WhatsApp Message
      </Button>
    );
  }, [rows, selectedRows]);

  const handleRowSelected = React.useCallback((state) => {
    console.log('state for rows selected', state);
    setSelectedRows(state.selectedRows);
  }, []);

  const list = (
    <>
      <Toastr ref={toastrRef} />
      {isModalOpen ? (
        <Modal
          selectedRows={selectedRows}
          onClose={() => {
            setModalOpen(false);
          }}
          onSubmit={(data) => {
            if (data) {
              const toastTitle = 'Success';
              const toastMessage = 'Mesaage Scheduled sucessfully';
              toastrRef.current?.add(toastMessage, toastTitle, { ...toastData });
            } else {
              const toastTitle = 'Error';
              const toastMessage = 'Error Occurred';
              toastrRef.current?.add(toastMessage, toastTitle, { ...toastData, status: 'Danger' });
            }
          }}
        />
      ) : (
        ''
      )}
      <Col>
        <FilterUser filterMethod={filterMethod} max_count={maxCount} />
      </Col>
      {!isTableLoading ? (
        <Col>
          <Col>
            <h1 className="text-heading">{startDate ? `Results from ${startDate} to ${endDate}` : ''}</h1>
          </Col>
          <Col offset={{ md: 8 }} breakPoint={{ xs: 12, md: 4 }}>
            <InputGroup fullWidth size="Small" status="Info">
              <input type="text" placeholder="Search..." onChange={(event) => handleInputChange(event)} />
            </InputGroup>
          </Col>
          <div
            style={{
              textAlign: 'center',
              backgroundColor: 'whitesmoke',
              color: 'black',
              padding: '18px',
              fontSize: '25px',
            }}
          >
            Active Users
          </div>

          <DataTable
            title="Active Users"
            columns={tableColumns}
            data={rows}
            pagination
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
            paginationPerPage={100}
            selectableRows
            contextActions={contextActions}
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
            striped
            dense
            highlightOnHover
            actions={actionsMemo}
          />
        </Col>
      ) : (
        <LoadingSpinner message="Loading User's List..." />
      )}
    </>
  );
  return <>{isLoading ? <LoadingSpinner message="User's List" /> : list}</>;
};
export default Users;
